// response.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogURL } from 'src/environments/environment';
import { SessionStorageService } from 'src/app/modules/hr-director/Services/Session Data Services/session-storage.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private _SSS: SessionStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

          if (event.url && event.url.includes(LogURL.tokenURL)) {

            // console.log("Token response (intercepted) => ", event.body);
            // console.log("Token response (intercepted) => ", event.body.userid);
            // console.log("Token response (intercepted) => ", event.body.token);

            if (event.body.status_message === "Invalid Credentials") {
              return event.clone({ body: {
                status_code: "10110101",
                status_message: "Invalid Credentials",
                token: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
                userid: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
              } });
            }
            else if (event.body.status_message === "User Disabled") {
              return event.clone({ body: {
                status_code: "10010101",
                status_message: "Disabled User",
                token: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
                userid: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
              } });
            }
            else if (event.body.status_message.includes("Your account is locked")) {
              return event.clone({ body: {
                status_code: "10010111",
                status_message: event.body.status_message,
                token: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
                userid: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
              } });
            }

            const session = {
              ATK: event.body.userid,
              UIDT: event.body.token
            }

            // Save to session
            this._SSS.storeSessionData("01110011011001010111001101110011011010010110111101101110", "sss", JSON.stringify(session));
            // Create a new HttpResponse with an empty body
            return event.clone({ body: {
              status_code: "00110101",
              status_message: "success",
              token: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
              userid: "H3Y1TSA-9RANK-Y0UFUC-K3D-3AD7Y",
            } });
          }

        }
        return event;
      })
    );
  }
}

