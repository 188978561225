import { SessionStorageService } from 'src/app/modules/hr-director/Services/Session Data Services/session-storage.service';
// hello.service.ts
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HELLOHITService } from '../HELLO HIT/hellohit.service';
import { LoginApiResponse, SessionResponse, UserAccess_Data_Dummy, UserAccess_Dummy } from '../HELLO Models/HELLO';
import { RBACService } from 'src/app/modules/hr-director/Services/RBAC Services/RBAC/rbac.service';
import { RBACAPIService } from 'src/app/modules/hr-director/Services/RBAC Services/RBAC API/rbacapi.service';

@Injectable({
  providedIn: 'root'
})
export class HELLOService {

  constructor(
    private _helloHitService: HELLOHITService,
    private _sessionStorageService: SessionStorageService,
    private _rbacService: RBACService,
    private _RBACAPIService: RBACAPIService
    ) {}


    session(username: string, password: string): Observable<SessionResponse> {
      const payload = { username, password, action: '' };
      return this._helloHitService.sendAuthTRequest(payload).pipe(
        switchMap(async (response: SessionResponse) => {
          if (response.status_code === "00110101" && response.status_message === 'success') {
            return response;
          }
          else if (response.status_message === 'success') {
            // await this.successLogin(response);

            return response;
          }
          else if (response.status_code === "10110101" && response.status_message === 'Invalid Credentials') {
            return response
          }
          else if (response.status_code === "10010101" && response.status_message === 'Disabled User') {
            return response
          }
          else if (response.status_code === "10010111") {
            return response
          }
          else {
            throw new Error('Login failed. Invalid response from server.');
          }
        }),
        catchError((error) => {
          return throwError(() => new Error('An error occurred during login'));
        })
      );
    }


    login(username: string, password: string): Observable<LoginApiResponse> {
      const payload = { username, password, action: 'login' };
      return this._helloHitService.sendLoginRequest(payload).pipe(
        switchMap(async (response: LoginApiResponse[]) => {
          if (response.length > 0 && response[0].sm === 'Success') {
            await this.successLogin(response[0]);
            return response[0];
          }
          else if (response.length > 0 && response[0].sm === 'Invalid Credentials! Please try again.') {
            return response[0];
          }
          else {
            throw new Error('Login failed. Invalid response from server.');
          }
        }),
        catchError((error) => {
          return throwError(() => new Error('An error occurred during login'));
        })
      );
    }



  async successLogin (data: LoginApiResponse) {


    this._RBACAPIService.setRBACData(UserAccess_Dummy);


    await this.saveUserData(data);

    const userAccessData = UserAccess_Data_Dummy;
    const check = this._sessionStorageService.storeSessionData("rbacData", "hakunaMatata", userAccessData);
    this._rbacService.accessDataSetter();
    this._rbacService.roleSetter();

  }


  // #region [Save Data - Employee & User]

    private saveUserData(userData: LoginApiResponse): void {

      // *********************
        // Identifiers for the keys
        const empDataIdentifier = 'empData';
        const userDataIdentifier = 'userData';
        const authTokenIdentifier = 'hakunaMatata';



        // Employee Data
        const empData = {
          n: userData.emp_number,
          N: userData.employeeName,
          fN: userData.fname,
          addr: userData.address,
          g: userData.gender,
          m: userData.marital_status,
          jT: userData.jobtitle,
        };
        this._sessionStorageService.storeSessionData("42910102101045", empDataIdentifier, empData);


        // User Data
        const useData = {
          i: userData.id,
          u: userData.user_name,
          n: userData.user_role_id,
          N: userData.roleName,
        };
        this._sessionStorageService.storeSessionData("429101002101045", userDataIdentifier, useData);


      // *********************


      // Save specific fields to 'auth_token' in sessionStorage
      const authToken = {
        id: userData.user_role_id,
        name: userData.roleName,
        resetPass: userData.resetpass,
        pendingLeaves: userData.pendingleaves,
      };
      this._sessionStorageService.storeSessionData("...", authTokenIdentifier, authToken);
      // console.log("auth=> ", authToken)
    }

    // resetPass: userData.resetpass,
    // pendingLeaves: userData.pendingleaves,

  // #endregion



  isAuthenticated(): boolean {
    const check = this._sessionStorageService.getSessionData_C("...", "hakunaMatata")
    return !!sessionStorage.getItem('...');
  }

  canProceed(): boolean {
    return (this._sessionStorageService.getSessionData_C("...", "hakunaMatata")?.resetPass === 0
            && this._sessionStorageService.getSessionData_C("...", "hakunaMatata")?.pendingLeaves === 0);
  }

  isResetPass(): boolean {
    return (this._sessionStorageService.getSessionData_C("...", "hakunaMatata")?.resetPass === 0);
  }

  isPendingLeaves(): boolean {
    return (this._sessionStorageService.getSessionData_C("...", "hakunaMatata")?.resetPass === 0);
  }

}
