import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../Session Data Services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RBACService {
  private userRole!: string;
  private rbacData!: RbacPermissions;

  constructor(
    private _sessionStorageService: SessionStorageService
  ) {

    this.accessDataSetter();
    this.roleSetter();
  }

  accessDataSetter () {
    this.rbacData =  this._sessionStorageService.getSessionData_C("rbacData", "hakunaMatata");

  }
  roleSetter () {
    this.userRole = this._sessionStorageService.getSessionData_C("...", "hakunaMatata")?.name;
  }


  // Use the ModuleName enum to check module access
  hasModuleAccess(moduleName: string): boolean {
    const roleData = this.rbacData[this.userRole];
    return roleData && roleData[moduleName] && roleData[moduleName].Access === 1;
  }

  // Check access to a specific feature within a module
  hasFeatureAccess(
    moduleName: string,
    feature: string,
    permission: 'R' | 'W'
  ): boolean {
    const roleData: RolePermissions | undefined = this.rbacData[this.userRole];
    if (!roleData) return false;

    const moduleData: ModulePermissions | undefined = roleData[moduleName];
    if (!moduleData || moduleData.Access !== 1) return false;

    const featureData: ModuleAction | number | undefined = moduleData[feature];
    if (typeof featureData === 'object' && featureData[permission] === 1) {
      return true;
    }

    return false;
  }
}




interface RbacPermissions {
  [role: string]: RolePermissions; // The complete structure with roles as keys
}
interface RolePermissions {
  [module: string]: ModulePermissions; // Each role can have multiple modules
}
interface ModulePermissions {
  Access: number;
  [action: string]: ModuleAction | number; // Allows additional actions as keys with ModuleAction type
}
interface ModuleAction {
  R?: number;
  W?: number;
}
