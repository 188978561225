import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
// import { LoginRedirectGuard, authGuardGuard } from './guards/auth-guard.guard';
import { HelloGuard, LoginRedirectGuard } from './HELLO HRMS/hello.guard';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [LoginRedirectGuard]},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path:'hr-director', canActivate: [HelloGuard], loadChildren: () => import('./modules/hr-director/hr-director.module').then((m) => m.HRDirectorModule)},
  {path:'**', component: NotFoundPageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
