import { Injectable } from '@angular/core';
import { RBACWarehouseService } from '../RBAC Warehouse/rbacwarehouse.service';

@Injectable({
  providedIn: 'root'
})
export class RBACEngineService {

  constructor(private rbacWarehouse: RBACWarehouseService) {}

  hasAccess(moduleName: string, featureName?: string): boolean {
    const rbacData = this.rbacWarehouse.getRBACData();

    // Assuming there might be multiple roles but we access data regardless of role
    const roleKey = Object.keys(rbacData)[0]; // Dynamically get the first role key
    const roleData = rbacData[roleKey]; // Now we have the role-specific data

    // Check if the module itself is accessible
    const moduleAccess = roleData?.[moduleName]?.Access;
    if (moduleAccess !== 1) {
      // If the module access is explicitly denied or not granted, deny access to it and its features
      return false;
    }

    // If no specific feature is requested, return true as the module access is granted
    if (!featureName) {
      return true;
    }

    // Check for specific feature access within the module
    const featurePermissions = roleData?.[moduleName]?.[featureName];
    if (!featurePermissions) {
      // If no permissions are defined for the feature, assume no access
      return false;
    }

    // Check if any of the permissions for the feature is granted (assuming "1" means granted)
    return Object.values(featurePermissions).some(permission => permission === 1);
  }

}
