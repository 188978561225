import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { KeyStorageService } from './KEY/key-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(private _keyStorageService: KeyStorageService) {}

  encrypt(data: any, dataIdentifier: string): string {
    //
    const { key, iv } = this.getOrGenerateKey(dataIdentifier);

    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const ivBytes = CryptoJS.enc.Utf8.parse(iv);

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), keyBytes, {
      iv: ivBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  decrypt(ciphertext: any, dataIdentifier: string): string {
    //
    const { key, iv } = this.getOrGenerateKey(dataIdentifier);

    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const ivBytes = CryptoJS.enc.Utf8.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(ciphertext, keyBytes, {
      iv: ivBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
  }

  private getOrGenerateKey(dataIdentifier: string): { key: string, iv: string } {
    //
    let keyInfo = this._keyStorageService.getKey(dataIdentifier);

    if (!keyInfo) {
      this._keyStorageService.generateKey(dataIdentifier);
      keyInfo = this._keyStorageService.getKey(dataIdentifier)!;
    }

    return keyInfo;
  }

  removeKey(dataIdentifier: string): void {
    //
    this._keyStorageService.deleteKey(dataIdentifier);
  }



  TSR (guid: string): string {
    let positions = [9, 10, 14, 15, 19, 20];
    let result = '';
    for (let i = 0; i < guid.length; i++) {
      if (!positions.includes(i)) {
        result += guid[i];
      }
    }
    return result;
  }


}
