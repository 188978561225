import { Injectable } from '@angular/core';
import { EncryptionService } from '../ENCService/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private cache = new Map<string, any>();

  constructor(private _encryptionService: EncryptionService) {} // Inject the EncryptionService



  storeSessionData(sessionKey: string, referenceName: string, data: any): void {
    //
    const encryptedData = this._encryptionService.encrypt(JSON.stringify(data), referenceName);
    sessionStorage.setItem(sessionKey, encryptedData);
    this.cache.set(sessionKey, data);
  }


  updateSessionDataWithNewProperties(sessionKey: string, referenceName: string, properties: {[key: string]: any}): void {
    //
    const itemString = sessionStorage.getItem(sessionKey);
    if (itemString) {
      try {
        // Decrypt the itemString
        const decryptedItemString = this._encryptionService.decrypt(itemString, referenceName);
        let item = JSON.parse(decryptedItemString);

        // Check if the item is an object
        if (item && typeof item === 'object' && !Array.isArray(item)) {
          // Iterate over the properties object and update/add each property
          for (const [key, value] of Object.entries(properties)) {
            item[key] = value;
          }

          // Re-encrypt and save the updated item back to session storage
          this.storeSessionData(sessionKey, referenceName, item);
        } else {
        }
      } catch (e) {
      }
    } else {
    }
  }


  getSessionData(sessionKey: string, referenceName: string, fields: string[]): any {
    let item = this.cache.get(sessionKey);

    // Parse and cache the item if not already cached
    if (!item) {
      const itemString = sessionStorage.getItem(sessionKey);
      if (itemString) {
        // Decrypt the itemString
        const decryptedItemString = this._encryptionService.decrypt(itemString, referenceName);

        item = JSON.parse(decryptedItemString);
        this.cache.set(sessionKey, item);
      } else {
        return null;
      }
    }

    const result: { [key: string]: any } = {}; // Using an index signature
    fields.forEach(field => {
      if (item.hasOwnProperty(field)) {
        result[field] = item[field];
      }
    });

    return result;
  }


  // Data availability checker
  sessionAvaCheck(sessionKey: string, referenceName: string): boolean {
    //
    const itemString = sessionStorage.getItem(sessionKey);
    if (!itemString) {
      return false;
    }

    try {
      this._encryptionService.decrypt(itemString, referenceName);
      return true;
    } catch (e) {
      return false;
    }
  }


  // Get Complete Data
  getSessionData_C(sessionKey: string, referenceName: string): any {

    let item = this.cache.get(sessionKey);
    if (item) {
      return item;
    }

    const itemString = sessionStorage.getItem(sessionKey);
    if (!itemString) {
      return null;
    }

    try {
      const decryptedItemString = this._encryptionService.decrypt(itemString, referenceName);
      item = JSON.parse(decryptedItemString);
      this.cache.set(sessionKey, item);
      return item;
    } catch (e) {
      return null;
    }
  }

  removeSessionData(sessionKey: string, referenceName: string): void {
    //

    // Remove the encryption/decryption key from the keymap
    this._encryptionService.removeKey(referenceName);

    // Remove the data from session storage
    sessionStorage.removeItem(sessionKey);

    // Also remove it from the cache if it exists
    this.cache.delete(sessionKey);
  }

  removeMultipleSessionData(dataToRemove: { sessionKey: string; referenceName: string }[]): void {
    //

    for (const item of dataToRemove) {
      const sessionKey = item.sessionKey;
      const referenceName = item.referenceName;

      // Remove the encryption/decryption key from the keymap
      this._encryptionService.removeKey(referenceName);

      // Remove the data from session storage
      sessionStorage.removeItem(sessionKey);

      // Also remove it from the cache if it exists
      this.cache.delete(sessionKey);
    }
  }


  // Clean Session Storage and SessionStorageService cache
  clearSessionStorage(): void {
    sessionStorage.clear();
    this.cache.clear();
  }
}
