import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { ReactiveFormsModule } from '@angular/forms';


import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { httpConfigInterceptor } from './modules/hr-director/Interceptor/http-config.interceptor';
import { ResponseInterceptor } from './HELLO HRMS/Interceptors/Response Interceptor/response.interceptor';


@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        NotFoundPageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        ToastModule], providers: [importProvidersFrom(BrowserModule),
        importProvidersFrom(BrowserAnimationsModule),
        MessageService,
        { provide: HTTP_INTERCEPTORS, useClass: httpConfigInterceptor, multi: true }, // Outgoing interceptor
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true } // Incoming interceptor
        , provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
