import { Injectable } from '@angular/core';
import { RBACWarehouseService } from '../RBAC Warehouse/rbacwarehouse.service';
import { RBACEngineService } from '../RBAC Engine/rbacengine.service';

@Injectable({
  providedIn: 'root'
})
export class RBACAPIService {

  constructor(
    private rbacWarehouseService: RBACWarehouseService,
    private rbacEngineService: RBACEngineService
  ) {}

  setRBACData(data: any): void {
    this.rbacWarehouseService.setRBACData(data);
  }

  hasAccess(moduleName: string, featureName?: string): boolean {
    return this.rbacEngineService.hasAccess(moduleName, featureName);
  }

}
