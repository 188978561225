import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DevToolsDetectorService {
  private bitFlag: number = 1; // 0 to show the message, 1 to show the alert
  private broadcastChannel: BroadcastChannel;
  private devtoolsOpen: boolean = false;

  constructor(private ngZone: NgZone, private router: Router) {
    this.broadcastChannel = new BroadcastChannel('devtools-detection');
    this.initialCheck();
    this.setupDetection();
    this.listenForDevToolsDetectionAcrossTabs();
    this.blockDevToolsKeys();
  }

  private initialCheck() {
    this.ngZone.runOutsideAngular(() => {
      const threshold = 160;

      const isDevToolsOpen = () => {
        const widthThreshold =
          window.outerWidth - window.innerWidth > threshold;
        const heightThreshold =
          window.outerHeight - window.innerHeight > threshold;
        return widthThreshold || heightThreshold;
      };

      const checkPerformance = () => {
        const t0 = performance.now();
        // // debugger; // Trigger the // debugger
        const t1 = performance.now();
        return t1 - t0 > 100; // Threshold in milliseconds
      };

      if (isDevToolsOpen() || checkPerformance()) {
        this.ngZone.run(() => {
          this.handleDevToolsOpen();
        });
      }
    });
  }

  private setupDetection() {
    const detectDevTools = () => {
      const threshold = 160;

      const isDevToolsOpen = () => {
        const widthThreshold =
          window.outerWidth - window.innerWidth > threshold;
        const heightThreshold =
          window.outerHeight - window.innerHeight > threshold;
        return widthThreshold || heightThreshold;
      };

      const checkPerformance = () => {
        const t0 = performance.now();
        // debugger; // Trigger the // debugger
        const t1 = performance.now();
        return t1 - t0 > 100; // Threshold in milliseconds
      };

      if (isDevToolsOpen() || checkPerformance()) {
        this.ngZone.run(() => {
          if (!this.devtoolsOpen) {
            this.devtoolsOpen = true;
            this.handleDevToolsOpen();
          }
        });
      } else {
        this.devtoolsOpen = false;
      }
    };

    setInterval(detectDevTools, 1000); // Check every second
  }

  private handleDevToolsOpen() {
    const correctKey = this.generateKey();
    const storedKey = sessionStorage.getItem('devaccesskey');

    if (storedKey && storedKey === correctKey) {
      // Allow user to pass
    } else {
      const userKey = prompt(
        'Developer tools detected! Enter the authentication key:'
      );
      if (userKey === correctKey) {
        sessionStorage.setItem('devaccesskey', userKey);
        // Allow user to pass
      } else {
        alert(
          'Incorrect key! Close the developer tools and refresh the page to proceed.'
        );
        this.clearStoragesAndRedirect();
      }
    }
    this.broadcastDevToolsDetection();
  }

  private clearStoragesAndRedirect() {
    sessionStorage.clear();
    localStorage.clear();
    // this.router.navigate(['/login']);
    window.history.replaceState(null, '', window.location.href);
    this.redirectUser();
  }

  private redirectUser() {
    sessionStorage.clear();
    localStorage.clear();
    window.history.replaceState(null, '', window.location.href);
    window.location.replace('https://google.com');
  }

  private listenForDevToolsDetectionAcrossTabs() {
    this.broadcastChannel.onmessage = (event) => {
      if (event.data === 'devtools-opened') {
        this.ngZone.run(() => {
          this.clearStoragesAndRedirect();
        });
      }
    };
  }

  private broadcastDevToolsDetection() {
    this.broadcastChannel.postMessage('devtools-opened');
  }

  private blockDevToolsKeys() {
    const correctKey = this.generateKey();
    const storedKey = sessionStorage.getItem('devaccesskey');

    if (storedKey && storedKey === correctKey) {
      return;
    }

    document.addEventListener('keydown', (event) => {
      if (
        (event.ctrlKey &&
          event.shiftKey &&
          (event.key === 'I' || event.key === 'J' || event.key === 'C')) ||
        (event.ctrlKey && event.key === 'U') ||
        event.key === 'F12'
      ) {
        event.preventDefault();
        alert(
          'Developer tools are disabled. Close the developer tools and refresh the page to proceed.'
        );
        return false;
      }
      return true;
    });
  }

  setBitFlag(flag: number) {
    this.bitFlag = flag;
  }

  generateKey(): string {
    const today = new Date();

    // Get the day, month, and year components
    const day = today.getDate();
    const month = today
      .toLocaleString('default', { month: 'short' })
      .slice(0, 2); // 'Jn' for 'June'
    const year = today.getFullYear().toString().slice(-2); // Last two digits of the year

    // Calculate the 10-minute interval part
    const hours = today.getHours();
    const minutes = Math.floor(today.getMinutes() / 10) * 10; // Rounds down to the nearest 10 minutes
    const interval = `${hours.toString().padStart(2, '0')}${minutes
      .toString()
      .padStart(2, '0')}`;

    // Generate the final key
    //console.log(`${month}${day}${year}.${interval}`);
    return `${month}${day}${year}.${interval}`;
  }
}
