// login.component.ts

import { Component, OnInit, Version } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoginApiResponse, SessionResponse } from 'src/app/HELLO HRMS/HELLO Models/HELLO';
import { HELLOService } from 'src/app/HELLO HRMS/HELLO Service/hello.service';
import { VersionService } from 'src/app/services/Version Service/version.service';
import { environment, LogURL } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  displayInvalidMessage: boolean = false;
  logging: boolean = false;
  errMessage: string = '';
  minutes: number = 0;
  loginForm: FormGroup;

  constructor(
    private auth: HELLOService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _messageService: MessageService,
    private _version: VersionService
    ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  version: string = "";

  ngOnInit(): void {

    this.version = this._version.getVersion();
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['hr-director']);
    }
  }

  // #region [Toggle Password Visibility]
    passwordVisible = false;

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    }
  // #endregion **************************

  isSubmitDisabled(): boolean {
    return this.loginForm.invalid || this.logging || this.minutes > 0;
  }

  hideInvalidMessage(): void {
    this.displayInvalidMessage = false;
  }

  userData: any;



  async onSubmit(): Promise<void> {

    if (this.loginForm.valid) {
      this.logging = true;
      const { username, password } = this.loginForm.value;



      await this.session(username, password);
      await this.login(username, password);

      this.logging = false;
    }
  }


  // Session
  async session(username: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auth.session(username, password).subscribe({
        next: (response: SessionResponse) => {
          if (response.status_message === 'success') {
            resolve();
          }
          else if (response.status_message === 'Invalid Credentials') {
            this.errMessage = 'Invalid credentials. Please try again.';
            this.displayInvalidMessage = true;
            this.logging = false;
            reject();
          }
          else if (response.status_message === 'Disabled User') {
            this.errMessage = 'Account disabled. Please contact administrator.';
            this.displayInvalidMessage = true;
            this.logging = false;
            reject();
          }
          else if (response.status_code === '10010111') {
            // Extract the number of minutes using a regular expression
            const match = response.status_message.match(/--(\d+)--/);
            this.minutes = 0;
          
            // If a match is found, extract the minutes
            if (match && match[1]) {
              this.minutes = parseInt(match[1]);
            }
          
          
            // Start the timer
            const countdownInterval = setInterval(() => {
              if (this.minutes > 0) {
                this.minutes -= 1;
                this.errMessage = `Your account is locked for ${this.minutes} minutes.`;
              } else {
                clearInterval(countdownInterval); // Stop the timer when time is up
                this.errMessage = `Account Unlocked.`;
              }
            }, 60000); // 60000 milliseconds = 1 minute
          
            this.errMessage = `Your account is locked for ${this.minutes} minutes.`;
            this.displayInvalidMessage = true;
            this.logging = false;
            reject();
          }
          
          else {
            this.logging = false;
            reject();
          }
        },
        error: (error) => {
          if (error.message === 'No Internet') {
            this.errMessage = 'No Internet';
            this._messageService.add({
              severity: 'error',
              summary: 'Network Error',
              detail: 'Please check your internet connection and try again.',
              key: 'loginT',
            });
          }
          else if (error.message === "An error occurred during login") {
            this.errMessage = `Please allow the following link to proceed:
            <a href="https://103.125.60.226/hrm_token/v1/gettoken" target="_blank">LINK</a>
            <br>Click on "Advanced" and then "Proceed" to bypass the security warning. Afterward, try logging in again.`;
            this.displayInvalidMessage = true;
          }
          else {
            this.errMessage = error.message;
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Oops! Something went wrong.',
              key: 'loginT',
            });
          }
          this.logging = false;
          reject();
        }
      });
    });
  }

  // Login
  async login(username: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auth.login(username, password).subscribe({
        next: (response: LoginApiResponse) => {
          if (response.sm === 'Success') {
            // Navigate to the dashboard or another page upon successful login
            const tempRedirectTo = "dashboard";
            this.redirectTo(tempRedirectTo);
            resolve();
          }
          else if (response.sm === 'Invalid Credentials! Please try again.') {
            this.displayInvalidMessage = true;
            this.errMessage = 'Invalid credentials. Please try again.';
            reject();
          }
          else {
            reject();
          }
        },
        error: (error) => {
          if (error.message === 'No Internet') {
            this.errMessage = 'No Internet';
          } else {
            this.errMessage = error.message;
          }
          reject();
        }
      });
    });
  }


  // #region [RBAC Redirector]
    redirectTo (redirectLink: string) {
      this.router.navigate(['hr-director/' + redirectLink]);
    }
  // #endregion



  // #region [Copy & Pase Disabler]
  onCopy(event: ClipboardEvent): void {
    event.preventDefault();
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }
  // #endregion
}
