import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DevToolsMessageService {
  constructor() {}

  displayDevToolsMessage() {
    const message = `
%cStop!
%cThis is a browser feature intended for developers.
If someone told you to copy and paste something here to enable a Facebook feature or "hack" someone's account, it is a scam and will give them access to your Facebook account.
%c
For more information, visit https://www.facebook.com/whitehat

Your Company Name
    `;
    const style1 = 'color: red; font-size: 50px; font-weight: bold;';
    const style2 = 'color: black; font-size: 20px;';
    const style3 = 'font-size: 12px;';

    // console.clear();
    // console.log(message, style1, style2, style3);
  }
}
