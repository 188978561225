import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogURL } from 'src/environments/environment';
import { SessionStorageService } from '../Services/Session Data Services/session-storage.service';
import { EncryptionService } from '../Services/ENCService/encryption.service';

@Injectable()
export class httpConfigInterceptor implements HttpInterceptor {
  constructor(
    private _sss: SessionStorageService,
    private _ENC: EncryptionService
  ) {}

  private apiToken = LogURL.loginToken;
  protected headersWithToken: string | null = null;

  private userInfo = [];

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const guid = request.url; // Assuming GUID is directly passed as the URL

    if (this._sss.sessionAvaCheck('429101002101045', 'userData')) {
      this.userInfo = this._sss.getSessionData('429101002101045', 'userData', [
        'n',
      ]);
    }

    if (request.headers.has('TOKEN')) {
      const token = request.headers.get('TOKEN');
      this.headersWithToken = token; // Save to const if needed
    }

    if (request.headers.has('TOKEN')) {
      // Clone the headers without the 'TOKEN' header
      const headers = request.headers.delete('TOKEN');
      // Assign the cloned headers to the modified request
      request = request.clone({ headers });
    }

    // Add security headers common to all requests
    const commonSecurityHeaders = {
      //'X-XSS-Protection': '1; mode=block',          // XSS protection
      //'X-Content-Type-Options': 'nosniff',          // Prevent MIME type sniffing
      //'X-Frame-Options': 'SAMEORIGIN',              // Prevent clickjacking
      'Content-Type': 'application/json'            // JSON Content-Type
    };

    if (this.headersWithToken) {
      if (this.headersWithToken === 'SMS') {
        const sessionInfo = JSON.parse(
          this._sss.getSessionData_C(
            '01110011011001010111001101110011011010010110111101101110',
            'sss'
          )
        );
        const authReq = request.clone({
          url: guid,
          setHeaders: {
            ...commonSecurityHeaders, // Spread security headers
            Authorization: `Bearer ${this.apiToken}`,
            ATK: `${this._ENC.TSR(sessionInfo.UIDT)}`,
            UIDT: `${sessionInfo.ATK}`,
          },
        });
        return next.handle(authReq);
      } else if (this.headersWithToken === 'nonSMS') {
        const authReq = request.clone({
          url: guid,
          setHeaders: {
            ...commonSecurityHeaders, // Spread security headers
            Authorization: `Bearer ${this.apiToken}`,
          },
        });
        return next.handle(authReq);
      } else {
        const authReq = request.clone({
          setHeaders: commonSecurityHeaders, // Apply common security headers for requests with no specific tokens
        });
        return next.handle(authReq);
      }
    } else {
      const authReq = request.clone({
        setHeaders: commonSecurityHeaders, // Apply common security headers for requests with no tokens
      });
      return next.handle(authReq);
    }
  }
}
