import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../Session Data Services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RBACWarehouseService {

  constructor(
    private _sessionStorageService: SessionStorageService
  ) { }

  private readonly storageKey = 'RBAC_Data';

  setRBACData(data: any): void {
    const existingData = sessionStorage.getItem(this.storageKey);
    if (!existingData) {
      // sessionStorage.setItem(this.storageKey, JSON.stringify(data));
      this._sessionStorageService.storeSessionData("RBACD", "RBACD", JSON.stringify(data));
    }
  }

  getRBACData(): any {
    // const data = sessionStorage.getItem(this.storageKey);
    const data = this._sessionStorageService.getSessionData_C("RBACD", "RBACD");

    return data ? JSON.parse(data) : null;
  }

}
