<p-toast
  position="top-left"
  key="loginT"
  >
</p-toast>


<section id="login">

  <div class="container">

    <!-- layer1 -->
    <div class="layer1">
      <div class="rect"></div>
      <div class="blob"></div>
      <div class="empty"></div>
    </div>

    <!-- layer2 -->
    <div class="layer2">
      <div class="logo-wrapper">
        <img src="../../assets/Branding/PRAL_3D_LOGO.png" alt="">
      </div>
      <div class="signeature">
        <span style="user-select: none;">EcoSys</span>
      </div>
      <div class="poweredBy">
        <!-- <span>Powered By: EcoSystem</span> -->
      </div>
    </div>

    <!-- layer3 -->
    <div class="layer3">

      <div class="layer3-inner">
        <header>
          <img src="../../assets/Branding/headLogo.png" alt="PRAL logo"
          [style.pointer-events]="'none'"
          [style.user-select]="'none'"
          (contextmenu)="false">
          <!-- <h3>PAKISTAN REVENUE AUTOMATION (PVT) LTD</h3> -->
        </header>

        <h2>Login</h2>

        <main>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

            <!-- username -->
            <div class="form-group">
              <label for="username">
                <i class="pi pi-user"></i>
                Username
              </label>
              <input  type="text"
                      class="form-control"
                      id="username" required
                      formControlName="username"
                      (focus)="hideInvalidMessage()">
            </div>

            <div class="form-group">
              <label for="password">
                <i class="pi pi-lock"></i>
                Password
              </label>
              <div class="passINP">
                <input  type="text"
                        class="form-control"
                        [ngClass]="{ 'passV': !passwordVisible }"
                        id="password"
                        required
                        formControlName="password"
                        (focus)="hideInvalidMessage()"
                        (keyup.enter)="onSubmit()"
                        autocomplete="off"
                        autocorrect="off"
                        autocapitalize="off"
                  >

                <i class="pi" [ngClass]="{ 'pi-eye-slash': passwordVisible, 'pi-eye': !passwordVisible }" (click)="togglePasswordVisibility()"></i>
              </div>

            </div>

            <div class="invalid-wrapper" [style.display]="displayInvalidMessage ? 'flex' : 'none'">
              <small id="invalidCredentials" class="invalid" [innerHTML]="errMessage"></small>
            </div>

            <button type="submit"
                    [disabled]="isSubmitDisabled()"
                    [ngClass]="{ 'disabled-button': isSubmitDisabled(), 'logging': logging }">
              <span class="login-label" *ngIf="!logging">Login</span>
              <!-- <span class="loader" *ngIf="logging"></span> -->

              <div  *ngIf="logging" class="loader">
                <div class="circle">
                  <div class="dot"></div>
                  <div class="outline"></div>
                </div>
                <div class="circle">
                  <div class="dot"></div>
                  <div class="outline"></div>
                </div>
                <div class="circle">
                  <div class="dot"></div>
                  <div class="outline"></div>
                </div>
                <div class="circle">
                  <div class="dot"></div>
                  <div class="outline"></div>
                </div>
              </div>
            </button>

          </form>
        </main>

        <footer>
          <span>{{ version }}</span>
          <p>© 2024 - 2025 Pakistan Revenue Automation (Pvt) Ltd.</p>
          <h5>All rights reserved.</h5>
        </footer>

      </div>

    </div>
  </div>

</section>

