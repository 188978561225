// hellohit.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogURL } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class HELLOHITService {
  private apiUrl = LogURL.loginURL;
  private tokUrl = LogURL.tokenURL;

  constructor(
    private http: HttpClient,
    private _messageService: MessageService
    ) {}

  sendLoginRequest(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'TOKEN': `SMS`,
    });

    return this.http.post(`${this.apiUrl}`, payload, { headers }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 0) {
          // Handle no internet connection
          this._messageService.add({
            severity: 'error',
            summary: 'Network Error',
            detail: 'Please check your internet connection and try again.',
            key: 'loginT',
          });
        }
        else if (error instanceof HttpErrorResponse && error.status === 401) {
          // Handle no internet connection
          this._messageService.add({
            severity: 'error',
            summary: 'Unauthorized',
            detail: 'Unauthorized to login. Please contact administrator.',
            key: 'loginT',
          });
        }
        throw '';
      })
    );
  }


  sendAuthTRequest(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'TOKEN': `nonSMS`,
    });

    return this.http.post(`${this.tokUrl}`, payload, { headers }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 0) {
          // Handle no internet connection
          this._messageService.add({
            severity: 'error',
            summary: 'Network Error',
            detail: 'Please check your internet connection and try again.',
            key: 'loginT',
          });
        }
        throw '';
      })
    );
  }

}
