// src/environments/environment.prod.ts

export interface Environment {
  production: boolean;
  baseUrls: {
    [key: string]: {
      baseUrl: string;
      token: string;
    };
  };
}

export const environment: Environment = {
  production: true,
  baseUrls: {
    default: { baseUrl: 'https://0.0.0.0', token: 'default-token-prod' },

    B0: { baseUrl: 'https://gw.fbr.gov.pk', token: '1db230df-4475-3478-b806-8a1cc5f4f7ff' },
  },
};

export const LogURL = {
  production: true,

  loginURL: 'https://gw.fbr.gov.pk/HRM/v1/user/get', // Over the Internet
  tokenURL: 'https://gw.fbr.gov.pk/hrm_token/v1/gettoken', // Over the Internet
  loginToken: '1db230df-4475-3478-b806-8a1cc5f4f7ff',
};
