import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class KeyStorageService {
  private keyMap = new Map<string, { key: string, iv: string }>();

  constructor() {
    // Load existing keyMap from sessionStorage
    this.loadKeyMap();

    window.addEventListener('beforeunload', () => {
      this.saveKeyMap();
    });
  }

  generateKey(dataIdentifier: string): void {
    //
    const key = this.generateRandomString(32);
    const iv = this.generateRandomString(16);
    this.keyMap.set(dataIdentifier, { key, iv });
  }

  getKey(dataIdentifier: string): { key: string, iv: string } | undefined {
    //
    return this.keyMap.get(dataIdentifier);
  }

  deleteKey(dataIdentifier: string): void {
    //
    this.keyMap.delete(dataIdentifier);
  }

  private deleteAllKeys(): void {
    //
    // Delete all stored keys when the tab is closed
    this.keyMap.clear();
  }

  private generateRandomString(length: number): string {
    //
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // #region [Temporary KeyMap Saver]
  private encryptionKey = 'w1Xocb_iolZHkjb_J3qxf6L3_HR8syMt';
  private saveKeyMap(): void {
    const keyMapArray = Array.from(this.keyMap.entries());
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(keyMapArray), this.encryptionKey).toString();
    sessionStorage.setItem('keyMap', encrypted);
  }

  private loadKeyMap(): void {
    const keyMapString = sessionStorage.getItem('keyMap');
    if (keyMapString) {
      let decryptedString;
      try {
        decryptedString = CryptoJS.AES.decrypt(keyMapString, this.encryptionKey).toString(CryptoJS.enc.Utf8);
      } catch (e) {
        return; // Exit if decryption fails
      }

      try {
        const keyMapArray = JSON.parse(decryptedString);
        this.keyMap = new Map(keyMapArray);
        sessionStorage.removeItem("keyMap");
      } catch (e) {
      }
    }
  }
  // #endregion

}
