import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { HELLOService } from './HELLO Service/hello.service';

// export const HelloGuard: CanActivateFn = () => {
//   const helloService = inject(HELLOService);
//   const router = inject(Router);

//   if (!helloService.isAuthenticated()) {
//     router.navigate(['/login']);
//     return false;
//   }
//   return true;
// };


export const HelloGuard: CanActivateFn = (route, state) => {
  const auth = inject(HELLOService); // Inject your AuthService

  let _router = inject(Router);

  if (!auth.isAuthenticated()) {

    _router.navigate(['login'])
    return false;
  }
  return auth.isAuthenticated();
};



export const LoginRedirectGuard: CanActivateFn = () => {
  // Use Angular's `inject` function to get instances of your services
  const authService = inject(HELLOService);
  const router = inject(Router);

  // Check if the user is authenticated
  if (authService.isAuthenticated()) {
    // Redirect authenticated users away from the login page
    router.navigate(['/hr-director']); // Adjust as needed for your default route
    return false; // Prevent access to the route
  }

  return true; // Allow access if not authenticated
};
