// src/app/services/version.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor() { }

  getVersion(): string {
    return "HRMS v1.0.0.0.0 (42.12.01.04)";
  }
  getDate(): string {
    return "Dec 04, 2024";
  }
}

