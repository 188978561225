import { Component } from '@angular/core';
import { DevToolsDetectionService } from './modules/hr-director/Services/UTILITIES/Dev Tools/dev-tools-detection.service';
import { DevToolsDetectorService } from './services/Dev Tools Detector Service/dev-tools-detector.service';
import { DevToolsMessageService } from './services/Dev Tools Message Service/dev-tools-message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HRMS';

  constructor (
    private _devToolsDetectorService: DevToolsDetectorService,
    private _devToolsMessageService: DevToolsMessageService
  ) {}

}
